import React from "react";
import moment from "moment";
import { Query } from "react-apollo";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import StepLabel from "@material-ui/core/StepLabel";

import { CAMPAIGN } from "../../../queries";
import { PICK_TYPES } from "@rotoql/common-services";

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: theme.spacing(120),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  divider: {
    margin: theme.spacing(2)
  },
  textinput: {
    margin: theme.spacing(1)
  }
});

function _pageTabDisplay(pageTab) {
  if (pageTab === "HomeSection") {
    return "Home";
  } else if (pageTab === "FeedSection") {
    return "Feed";
  } else if (pageTab === "MyPicksSection") {
    return "My Picks";
  } else if (pageTab === "SettingsSection") {
    return "Settings";
  }
  return "N/A";
}

function _pageTypeDisplay(pageType) {
  if (pageType === "GameView") {
    return "Upcoming Game";
  } else if (pageType === "LiveGameView") {
    return "Live Game";
  } else if (pageType === "BetNowModal") {
    return "Bet Now Modal";
  }
  return "N/A";
}

function _betTypeDisplay(betType) {
  if (PICK_TYPES[betType]) {
    return PICK_TYPES[betType].name;
  }
  return "N/A";
}

function _pageIndexDisplay(pageIndex) {
  if (pageIndex === 0) {
    return "Value Bets";
  } else if (pageIndex === 1) {
    return "Public Bets";
  } else if (pageIndex === 2) {
    return "Sharp Bets";
  } else if (pageIndex === 3) {
    return "Line Movement";
  }
  return "N/A";
}

function Campaign(props) {
  const { match, classes } = props;
  const { id } = match.params;
  return (
    <Query
      query={CAMPAIGN}
      variables={{ id }}
      fetchPolicy="cache-and-network"
      errorPolicy="all"
    >
      {resp => {
        const { loading, errors, data } = resp;
        if (loading) {
          return (
            <Typography variant="h4" className={classes.grow}>
              "Loading Push Notification Campaign....";
            </Typography>
          );
        }
        if (errors) {
          return (
            <Typography variant="h4" className={classes.grow}>
              "Error Fetching Push Notification Campaign.";
            </Typography>
          );
        }
        const {
          product,
          name,
          startTs,
          endTs,
          title,
          body,
          sentCount,
          openCount
        } = data.auth.pushnoCampaign;
        let { dataPayload } = data.auth.pushnoCampaign;
        if (!dataPayload) {
          dataPayload = {};
        } else {
          dataPayload = JSON.parse(dataPayload);
        }
        return (
          <div className={classes.paper}>
            <Typography variant="h4">
              Push Campaign: {product} {name}
            </Typography>

            {/* Timing Metrics */}
            <Divider className={classes.divider} />
            <Typography variant="h6">Timing Metrics</Typography>
            <Toolbar>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Send Start Time:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {moment
                    .utc(startTs)
                    .local()
                    .format("ddd MM-DD hh:mm a")}
                </Typography>
              </FormControl>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Send End Time:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {endTs
                    ? moment
                        .utc(endTs)
                        .local()
                        .format("ddd MM-DD hh:mm a")
                    : "In Progress"}
                </Typography>
              </FormControl>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Send Time:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {endTs
                    ? moment.duration(moment(startTs).diff(endTs)).humanize()
                    : moment.duration(moment.utc(startTs).diff()).humanize()}
                </Typography>
              </FormControl>
            </Toolbar>

            {/* Open Metrics */}
            <Divider className={classes.divider} />
            <Typography variant="h6">Open Metrics</Typography>
            <Toolbar>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Send Count:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {sentCount}
                </Typography>
              </FormControl>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Open Count:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {openCount}
                </Typography>
              </FormControl>
              <FormControl fullWidth className={classes.textinput}>
                <StepLabel>Open Percentage:</StepLabel>
                <Typography variant="body2" className={classes.grow}>
                  {sentCount ? (100 * openCount) / sentCount : 0} %
                </Typography>
              </FormControl>
            </Toolbar>

            {/* Payload */}
            <Divider className={classes.divider} />
            <Typography variant="h6">Send Payload</Typography>
            <FormControl fullWidth className={classes.textinput}>
              <StepLabel>Subject:</StepLabel>
              <Typography variant="body2" className={classes.grow}>
                {title}
              </Typography>
            </FormControl>
            <FormControl fullWidth className={classes.textinput}>
              <StepLabel>Message:</StepLabel>
              <Typography variant="body2" className={classes.grow}>
                {body}
              </Typography>
            </FormControl>
            <FormControl fullWidth className={classes.textinput}>
              <Typography variant="h6">Deeplink Data:</Typography>
              <Toolbar>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>Tab:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {_pageTabDisplay(dataPayload.tab)}
                  </Typography>
                </FormControl>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>Type:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {_pageTypeDisplay(dataPayload.pageType)}
                  </Typography>
                </FormControl>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>Feed:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {_pageIndexDisplay(dataPayload.pageIndex)}
                  </Typography>
                </FormControl>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>Bet Type:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {_betTypeDisplay(dataPayload.betType)}
                  </Typography>
                </FormControl>
              </Toolbar>
              <Toolbar>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>Event:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {dataPayload.eventId ? dataPayload.eventId : "N/A"}
                  </Typography>
                </FormControl>
                <FormControl fullWidth className={classes.textinput}>
                  <StepLabel>League:</StepLabel>
                  <Typography variant="body2" className={classes.grow}>
                    {dataPayload.league ? dataPayload.league : "N/A"}
                  </Typography>
                </FormControl>
              </Toolbar>
            </FormControl>
          </div>
        );
      }}
    </Query>
  );
}

export default withStyles(styles)(Campaign);
