import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MultiSelect from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

import { DateTimePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { withApollo, Mutation } from "react-apollo";
import Moment from "moment";
import {
  SPORTS_LIST,
  PICK_TYPES,
  DEFAULT_SPORT
} from "@rotoql/common-services";

import { calcuate_breakdown_from_run_frequency } from "../../../helpers";
import { UPDATE_PUSHNO, CREATE_PUSHNO } from "../../../mutations";
import {
  SUBSCRIPTION_LEVELS,
  LEAGUE_MAP,
  LEAGUE_DISPLAY_NAMES
} from "../../../constants";
import { PUSHNO } from "../../../queries";
import { US_STATES } from "../../../data/geo";
import LeaguePicker from "../Common/leaguePicker";
import MatchupPicker from "../Common/matchupPicker";
import { ButtonGroup } from "@material-ui/core";

const styles = theme => ({
  paper: {
    flex: 1,
    maxWidth: "1232px"
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  shortInput: {
    width: "400px",
    marginRight: "16px"
  },
  longInput: {
    width: "100%",
    marginBottom: "20px"
  },
  multiselectInputLeft: {
    flex: 1,
    marginRight: "8px"
  },
  multiselectInputRight: {
    flex: 1,
    marginLeft: "8px"
  },
  checkBox: {
    paddingBottom: 0
  },
  nextRunInput: {
    width: "200px"
  },
  timeFragmentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100px",
    marginLeft: "16px"
  },
  timeFragmentInput: {
    marginLeft: "5px"
  },
  matchupPicker: {
    margin: "0 16px"
  }
});

const SUB_LEVEL_GROUPS = [
  { name: "Premium", level: 1 },
  { name: "Pro", level: 2 },
  { name: "Vip", level: 3 },
  { name: "Sharp", level: 4 }
];

class Pushno extends Component {
  constructor(props) {
    super(props);
    // state vars named kwarg states map to kwargs sub dictionary on the server side
    this.state = {
      id: null,
      executionFrequency: 0,
      usernames: [],
      lastExecutionTime: Moment().toISOString(),
      leagueId: "",
      kwargProduct: "",
      kwargRunOnce: true,
      kwargMessage: "",
      kwargTitle: "",
      kwargCampaignName: "",
      kwargUserStateList: [],
      //kwargSubscriptionList: [],
      kwargPageIndex: "0",
      kwargTab: "HomeSection",
      kwargPageType: "GameView",
      kwargBetType: "spread",
      kwargMatchupId: "",
      leagues: {},
      matchupStartDate: Moment(),
      matchupEndDate: Moment().add(3, "days"),
      disableTabSelect: false,
      betTypeEls: [],
      missingSportSubName: null,
      currentSubscriptionList: []
    };
  }

  subIdToNameList = subIdList => {
    const lookup = {};

    subIdList.forEach(item => {
      lookup[item] = true;
    });

    return SUBSCRIPTION_LEVELS.filter(item => lookup[item.value]);
  };

  async componentDidMount() {
    const { match, client } = this.props;
    /*
    If we have an id when landing on this view then we query for the push notification
    and set our intiial state variables to that, otherwise we will set defaults.
    We let the then call update the state so the blank form populates as soon as data
    comes back to the system.
    */
    if (match.params.id) {
      try {
        const resp = await client.query({
          query: PUSHNO,
          variables: { id: match.params.id },
          fetchPolicy: "network-only"
        });
        const { scheduledOperation } = resp.data.auth;
        if (!scheduledOperation) {
          console.log("ERROR");
        } else {
          const {
            executionFrequency,
            lastExecutionTime,
            kwargs,
            usernames,
            id
          } = scheduledOperation;
          const {
            product: kwargProduct,
            run_once,
            title: kwargTitle,
            campaign_name: kwargCampaignName,
            message: kwargMessage,
            data_payload,
            user_state_list,
            subscription_list,
            exclude_sport_name
          } = JSON.parse(kwargs);

          console.log(kwargs);

          // in order to display push notifications that's already sent, we need to
          // parse the kwargs that was saved in the database
          let newState = {
            id,
            executionFrequency,
            lastExecutionTime,
            kwargProduct,
            kwargTitle,
            kwargCampaignName,
            kwargMessage,
            missingSportSubName: exclude_sport_name,
            kwargUserStateList: user_state_list,
            currentSubscriptionList: this.subIdToNameList(subscription_list),
            usernames
          };
          newState.currentStateList = US_STATES.filter(state => {
            return user_state_list.some(stateStr => state.value === stateStr);
          });
          // newState.currentSubscriptionList = SUBSCRIPTION_LEVELS.filter(sub => {
          //   return subscription_list.some(subStr => sub.value === subStr);
          // });
          if (!run_once) {
            newState.kwargRunOnce = false;
          }
          if (data_payload) {
            if (data_payload.page_index) {
              newState.kwargPageIndex = data_payload.page_index;
            }
            if (data_payload.tab) {
              newState.kwargTab = data_payload.tab;
            }
            if (data_payload.matchup_id) {
              newState.kwargMatchupId = data_payload.matchup_id;
            }
            if (data_payload.page_type) {
              newState.kwargPageType = data_payload.page_type;
            }
            if (data_payload.league_id) {
              newState.leagueId = data_payload.league_id;
              newState.betTypeEls = this._getBetTypeEls(newState.leagueId);
            }
            if (data_payload.betType) {
              newState.kwargBetType = data_payload.betType;
            }
            if (data_payload.page_type === "BetNowModal") {
              newState.disableTabSelect = true;
            }
          }
          this.setState(newState);
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  /*
  This is just a set state call but it runs a minor calculation and is called in
  several places in the form so it is broken out here.
  */
  _update_execution_frequency(days, hours, minutes, seconds) {
    this.setState({
      executionFrequency:
        seconds + 60 * minutes + 60 * 60 * hours + 60 * 60 * 24 * days
    });
  }

  /*
  This generates a list of JSX menu items for the bet type list based on the league that
  is selected.  It queries our internal league cache and gets the league name for the
  selected league ID and uses that to find what bet types are allowed for that league.
  It defaults to all of them if it doesn't find anything.  The bet type elements are held
  in the state so when our cache resolution finishes it can display them to the user and
  it will rerender automatically.

  The league id is updated here as well in the state so that we don't have to call set state
  twice in the on change handler.
  */
  _generateBetTypeElements = leagueId => {
    const betTypeEls = [];
    if (!leagueId) {
      this.setState({ betTypeEls, leagueId });
    } else {
      this.setState({ betTypeEls: this._getBetTypeEls(leagueId), leagueId });
    }
  };

  _getBetTypeEls = leagueId => {
    let betTypeEls = [],
      name,
      key;
    for (key in LEAGUE_MAP) {
      if (LEAGUE_MAP[key] === leagueId) {
        name = key;
        break;
      }
    }
    let currentSportObj = DEFAULT_SPORT;
    if (name) {
      currentSportObj = SPORTS_LIST[name.toLowerCase()];
    }
    for (let i = 0; i < currentSportObj.pickTypes.length; i++) {
      betTypeEls.push(
        <MenuItem
          key={currentSportObj.pickTypes[i]}
          value={currentSportObj.pickTypes[i]}
        >
          {PICK_TYPES[currentSportObj.pickTypes[i]].name}
        </MenuItem>
      );
    }
    return betTypeEls;
  };

  /*
  The form specifically for the section of deeplink fields that the push notification
  requires.
  */
  deeplink_form() {
    const {
      kwargPageIndex,
      kwargTab,
      kwargPageType,
      kwargMatchupId,
      kwargBetType,
      leagueId,
      matchupStartDate,
      matchupEndDate,
      disableTabSelect,
      betTypeEls
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <hr style={{ margin: "60px 0 40px" }} />
        <Typography style={{ marginBottom: "40px" }} variant="h6">
          Deeplink Settings
        </Typography>
        {/* "Leave matchup blank to deactivate deeplink push notification." */}
        <div style={{ marginBottom: "40px" }} className={classes.flexRowCenter}>
          <LeaguePicker
            selectedId={leagueId}
            onChangeMethod={e => {
              /*
                We want to update the bet types here so we call the generate bet type elements method.
                This will also update the leauge id in state so we don't have to call two set states.
              */
              this._generateBetTypeElements(e.target.value);
            }}
          />
          <MatchupPicker
            className={classes.matchupPicker}
            selectedId={kwargMatchupId}
            leagueId={leagueId}
            startDate={matchupStartDate}
            endDate={matchupEndDate}
            onChangeMethod={e => {
              this.setState({ kwargMatchupId: e.target.value });
            }}
          />
          <FormControl
            className={classes.shortInput}
            style={{ marginRight: 0 }}
          >
            <InputLabel htmlFor="page-type">Page type</InputLabel>
            <Select
              value={kwargPageType}
              disabled={!kwargMatchupId}
              onChange={e => {
                const updateState = { kwargPageType: e.target.value };
                if (e.target.value === "BetNowModal") {
                  updateState.kwargTab = "FeedSection";
                  updateState.disableTabSelect = true;
                } else {
                  updateState.disableTabSelect = false;
                }
                this.setState(updateState);
              }}
            >
              <MenuItem value="">
                <em>Select event to specify a game view.</em>
              </MenuItem>
              <MenuItem key="GameView" value="GameView">
                Upcoming Game
              </MenuItem>
              <MenuItem key="LiveGameView" value="LiveGameView">
                Live Game
              </MenuItem>
              <MenuItem key="BetNowModal" value="BetNowModal">
                Bet Now Modal
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginBottom: "40px" }} className={classes.flexRowCenter}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              label="Matchup list start date"
              value={matchupStartDate}
              className={classes.shortInput}
              onChange={date => this.setState({ matchupStartDate: date })}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              label="Matchup list end date"
              value={matchupEndDate}
              className={classes.shortInput}
              onChange={date => this.setState({ matchupEndDate: date })}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ marginBottom: "40px" }} className={classes.flexRowCenter}>
          <FormControl className={classes.shortInput}>
            <InputLabel htmlFor="page-tab">
              {disableTabSelect
                ? "DISABLED DUE TO BetNowModal"
                : "In App Tab the user will be sent to."}
            </InputLabel>
            <Select
              value={kwargTab}
              disabled={disableTabSelect}
              onChange={e => this.setState({ kwargTab: e.target.value })}
            >
              <MenuItem key="HomeSection" value="HomeSection">
                Home
              </MenuItem>
              <MenuItem key="FeedSection" value="FeedSection">
                Feed
              </MenuItem>
              <MenuItem key="MyPicksSection" value="MyPicksSection">
                My Picks
              </MenuItem>
              <MenuItem key="SettingsSection" value="SettingsSection">
                Settings
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.shortInput}>
            <InputLabel htmlFor="page-index">
              {disableTabSelect
                ? "Determines team selected on modal"
                : "Specific bet feed that the user lands on."}
            </InputLabel>
            <Select
              value={kwargPageIndex}
              onChange={e => this.setState({ kwargPageIndex: e.target.value })}
            >
              <MenuItem key="0" value={0}>
                Value Bets
              </MenuItem>
              <MenuItem key="1" value={1}>
                Public Bets
              </MenuItem>
              <MenuItem key="2" value={2}>
                Sharp Bets
              </MenuItem>
              <MenuItem key="3" value={3}>
                Line Movement
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.shortInput}
            style={{ marginRight: 0 }}
          >
            <InputLabel htmlFor="page-type">
              {leagueId ? "Bet Type" : "Select league to display bet types"}
            </InputLabel>
            <Select
              value={kwargBetType}
              disabled={!leagueId}
              onChange={e => {
                const updateState = { kwargBetType: e.target.value };
                if (e.target.value === "BetNowModal") {
                  updateState.kwargTab = "FeedSection";
                  updateState.disableTabSelect = true;
                } else {
                  updateState.disableTabSelect = false;
                }
                this.setState(updateState);
              }}
            >
              {betTypeEls}
            </Select>
          </FormControl>
        </div>
      </React.Fragment>
    );
  }

  /*
  This pushno form method is where most of the view lives.  It is basically one big form that
  handles one part or another of these fields.  Gross right?  Nothing involved.

  The method that calls the mutation query is passed into the form so it can be used on
  the submit button which will directly call the method.  There is no need to call an
  await on it or anything as calling the method triggers the Mutation element to rerender
  either with response data, a loading flag, or errors that have returned.
  */
  pushno_form(mutation_query, loading) {
    const { classes } = this.props;
    let label = "";
    if (this.props.match.params.id != null) {
      label = "Update Pushno";
    } else {
      label = "Create Pushno";
    }
    const {
      id,
      executionFrequency,
      usernames,
      lastExecutionTime,
      kwargProduct,
      kwargRunOnce,
      kwargMessage,
      kwargTitle,
      kwargCampaignName,
      kwargUserStateList,
      //kwargSubscriptionList,
      kwargBetType,
      kwargPageIndex,
      kwargTab,
      kwargPageType,
      kwargMatchupId,
      leagueId,
      missingSportSubName
    } = this.state;
    const {
      days,
      hours,
      minutes,
      seconds
    } = calcuate_breakdown_from_run_frequency(executionFrequency);

    const { currentStateList, currentSubscriptionList } = this.state;

    return (
      <div className={classes.paper}>
        <Typography variant="h6">{label}</Typography>
        <div style={{ marginBottom: "20px" }}>
          <FormControl className={classes.shortInput}>
            <InputLabel>Product</InputLabel>
            <Select
              value={kwargProduct}
              onChange={e => this.setState({ kwargProduct: e.target.value })}
              input={<Input name="product" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="bet">Bet</MenuItem>
              {/* <MenuItem value="squad">Squad</MenuItem> */}
            </Select>
          </FormControl>
          <TextField
            label="Campaign Name"
            value={kwargCampaignName}
            className={classes.shortInput}
            onChange={e => this.setState({ kwargCampaignName: e.target.value })}
          />
          <TextField
            label="Title"
            value={kwargTitle}
            className={classes.shortInput}
            style={{ marginRight: 0 }}
            onChange={e => this.setState({ kwargTitle: e.target.value })}
          />
        </div>
        <TextField
          label="Message"
          value={kwargMessage}
          className={classes.longInput}
          onChange={e => this.setState({ kwargMessage: e.target.value })}
        />
        <TextField
          label="Usernames"
          value={usernames.join(",")}
          className={classes.longInput}
          onChange={e =>
            this.setState({ usernames: e.target.value.split(",") })
          }
        />
        <div
          style={{ alignItems: "flex-start" }}
          className={classes.flexRowCenter}
        >
          <MultiSelect
            className={classes.multiselectInputLeft}
            isDisabled={currentSubscriptionList.length > 0}
            options={US_STATES}
            value={currentStateList}
            onChange={e => {
              if (e !== null) {
                // This component is wack
                this.setState({
                  kwargUserStateList: e.map(item => item.value)
                });
              } else {
                this.setState({ kwargUserStateList: [] });
              }
            }}
            placeholder="Select states(s) to filter on"
            isMulti
          />
          <div style={{ flex: 1 }}>
            <MultiSelect
              className={classes.multiselectInputRight}
              isDisabled={kwargUserStateList.length > 0}
              options={SUBSCRIPTION_LEVELS}
              value={currentSubscriptionList}
              onChange={e => {
                this.setState({ currentSubscriptionList: e });
              }}
              placeholder="Select subscription level(s) to filter on"
              isMulti
            />
            <div style={{ padding: "10px" }}>
              <ButtonGroup>
                <Button
                  onClick={e => {
                    this.setState({ currentSubscriptionList: [] });
                  }}
                >
                  CLEAR
                </Button>
                {SUB_LEVEL_GROUPS.map(subGroup => (
                  <Button
                    onClick={e => {
                      console.log(e);
                      const currentLabels = {};
                      const updatedCurrentSubscriptionList = currentSubscriptionList.slice();
                      currentSubscriptionList.forEach(item => {
                        currentLabels[item.label] = true;
                      });

                      SUBSCRIPTION_LEVELS.forEach(item => {
                        if (
                          item.level === subGroup.level &&
                          !currentLabels[item.label]
                        ) {
                          updatedCurrentSubscriptionList.push(item);
                        }
                      });
                      // add
                      this.setState({
                        currentSubscriptionList: updatedCurrentSubscriptionList
                      });
                    }}
                  >
                    + {subGroup.name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <Typography variant="body1" style={{ marginLeft: "7.5px" }}>
              <small>
                Note: Selecting FREE will supersede all paid subscriptions
              </small>
            </Typography>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <FormControl className={classes.shortInput}>
            <InputLabel>Target users without this league</InputLabel>
            <Select
              value={missingSportSubName}
              onChange={e =>
                this.setState({ missingSportSubName: e.target.value })
              }
              input={<Input name="excludedSportName" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {LEAGUE_DISPLAY_NAMES.map(item => (
                <MenuItem value={item.value}>{item.display}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <form className={classes.flexRowCenter} noValidate>
          <div>
            <InputLabel>Next Runtime</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                id="datetime-local"
                value={Moment(lastExecutionTime).add(
                  executionFrequency,
                  "seconds"
                )}
                className={classes.nextRunInput}
                InputLabelProps={{
                  style: {}
                }}
                onChange={date =>
                  this.setState({
                    lastExecutionTime: Moment(date)
                      .subtract(executionFrequency, "seconds")
                      .toISOString()
                  })
                }
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: "16px"
            }}
          >
            <InputLabel>Run Once</InputLabel>
            <Checkbox
              className={classes.checkBox}
              checked={kwargRunOnce}
              onChange={(e, checked) =>
                this.setState({ kwargRunOnce: checked })
              }
            />
          </div>
          <div style={{ marginLeft: "16px" }}>
            <InputLabel>Run Frequency</InputLabel>
            <div className={classes.flexRowCenter}>
              <div className={classes.timeFragmentContainer}>
                <InputLabel>Days: </InputLabel>
                <Input
                  className={classes.timeFragmentInput}
                  value={days}
                  label="days"
                  type="number"
                  disabled={kwargRunOnce}
                  onChange={e =>
                    this._update_execution_frequency(
                      e.target.value,
                      hours,
                      minutes,
                      seconds
                    )
                  }
                />
              </div>
              <div className={classes.timeFragmentContainer}>
                <InputLabel>Hours: </InputLabel>
                <Input
                  className={classes.timeFragmentInput}
                  value={hours}
                  label="hours"
                  type="number"
                  disabled={kwargRunOnce}
                  onChange={e =>
                    this._update_execution_frequency(
                      days,
                      e.target.value,
                      minutes,
                      seconds
                    )
                  }
                />
              </div>
              <div className={classes.timeFragmentContainer}>
                <InputLabel>Minutes: </InputLabel>
                <Input
                  className={classes.timeFragmentInput}
                  value={minutes}
                  label="minutes"
                  type="number"
                  disabled={kwargRunOnce}
                  onChange={e =>
                    this._update_execution_frequency(
                      days,
                      hours,
                      e.target.value,
                      seconds
                    )
                  }
                />
              </div>
            </div>
          </div>
        </form>
        {this.deeplink_form()}
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              const variables = {
                lastExecutionTime,
                usernames,
                executionFrequency,
                kwargs: JSON.stringify({
                  campaign_name: kwargCampaignName,
                  title: kwargTitle,
                  message: kwargMessage,
                  product: kwargProduct,
                  run_once: kwargRunOnce,
                  user_state_list: kwargUserStateList,
                  subscription_list: currentSubscriptionList.map(
                    item => item.value
                  ),
                  exclude_sport_name: missingSportSubName,
                  data_payload: {
                    bet_type: kwargBetType,
                    page_index: kwargPageIndex,
                    tab: kwargTab,
                    page_type: kwargPageType,
                    matchup_id: kwargMatchupId,
                    league_id: leagueId
                  },
                  dry_run: false
                })
              };
              if (id) {
                variables.id = id;
              }
              try {
                await mutation_query({
                  variables,
                  errorPolicy: "all"
                });
                this.props.history.push("/pushes");
              } catch (e) {
                console.log(e.message);
              }
            }}
            disabled={
              loading ||
              kwargProduct === "" ||
              kwargCampaignName === "" ||
              kwargTitle === "" ||
              kwargMessage === ""
            }
          >
            {loading ? (
              <div className={classes.flexRowCenter}>
                <Typography variant="h6">Submitting</Typography>
                <CircularProgress style={{ marginLeft: "10px" }} size="20" />
              </div>
            ) : (
              label
            )}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { match } = this.props;
    return (
      <Mutation mutation={match.params.id ? UPDATE_PUSHNO : CREATE_PUSHNO}>
        {(mutation_query, mutation_resp) => {
          const { error, loading } = mutation_resp;
          if (error) {
            if (error.graphQLErrors.length > 0) {
              return <p>Error: {error.graphQLErrors[0][0].title}</p>;
            }
            return <p>Error: {error + ""}</p>;
          }
          return this.pushno_form(mutation_query, loading);
        }}
      </Mutation>
    );
  }
}

export default withApollo(withStyles(styles)(Pushno));
