import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withApollo } from "react-apollo";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import moment from "moment";
import { Query } from "react-apollo";
import { MATCHUPS } from "../../../queries";

const styles = theme => ({
  marginLeft: {
    margin: "0 16px"
  },
  textinput: {
    width: "700px"
  },
  multilineMenu: {
    height: "auto"
  }
});

class MatchupPicker extends Component {
  matchupRow(matchup) {
    const { classes } = this.props;
    let { startTime } = matchup;
    return (
      <MenuItem
        key={matchup.id}
        value={matchup.id}
        className={classes.multilineMenu}
      >
        <span>
          <Typography variant="body2">
            <b>Home:</b> {matchup.homeTeam.fullName} <b>Away:</b>{" "}
            {matchup.awayTeam.fullName} <b>Start Time: </b>{" "}
            <Moment format="ddd MM-DD hh:mm a">
              {moment.utc(startTime).local()}
            </Moment>{" "}
            EST
          </Typography>
        </span>
      </MenuItem>
    );
  }

  render() {
    const {
      classes,
      onChangeMethod,
      leagueId,
      startDate,
      endDate,
      selectedId,
      className
    } = this.props;

    if (!leagueId) {
      return (
        <FormControl
          className={`${className ? className : ""} ${classes.textinput}`}
        >
          <InputLabel>
            Select league and date range to get matchups: OPTIONAL
          </InputLabel>
          <Select value="" disabled={true}>
            <MenuItem value="">
              Select league and date range to get matchups
            </MenuItem>
          </Select>
        </FormControl>
      );
    }
    return (
      <Query
        query={MATCHUPS}
        variables={{
          leagueId: leagueId,
          startDate: startDate,
          endDate: endDate
        }}
        fetchPolicy="cache-and-network"
        errorPolicy="all"
      >
        {resp => {
          const { loading, errors, data, refetch } = resp;
          if (loading) {
            return (
              <Typography variant="body2" className={classes.marginLeft}>
                Loading matchup list...
              </Typography>
            );
          }
          if (errors) {
            return (
              <Typography variant="body2" className={classes.marginLeft}>
                Errors loading matchup list : {JSON.stringify(errors)}
              </Typography>
            );
          }
          if (!data || !data.auth || !data.auth.matchups) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "0 16px"
                }}
              >
                <Typography variant="body2" className={classes.grow}>
                  No Matchups:
                </Typography>
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="contained"
                  color="primary"
                  align="right"
                  onClick={() => refetch()}
                >
                  Retry
                </Button>
              </div>
            );
          }
          const menuItems = [<MenuItem value="" key="" />];
          data.auth.matchups.forEach(matchup => {
            menuItems.push(this.matchupRow(matchup));
          });
          return (
            <FormControl
              className={`${className ? className : ""} ${classes.textinput}`}
            >
              <InputLabel>Matchup to link this pushno to.</InputLabel>
              <Select
                value={selectedId}
                onChange={e => {
                  onChangeMethod(e);
                }}
              >
                {menuItems}
              </Select>
            </FormControl>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(withStyles(styles)(MatchupPicker));
