import gql from "graphql-tag";

export const PUSHNOS = gql`
  query scheduledOperations {
    auth {
      scheduledOperations(
        service: AUTH
        operation: "auth.scheduled.operations.pushno.pushno"
      ) {
        id
        kwargs
        lastExecutionTime
        executionFrequency
        usernames
      }
    }
  }
`;

export const BET_SCHEDULED_OPERATIONS = gql`
  query scheduledOperations {
    auth {
      scheduledOperations(service: BET, operation: "") {
        id
        operation
        leaseHolder
        lastExecutionTime
        leaseExpiryTime
        executionFrequency
        kwargs
      }
    }
  }
`;

export const INTEL_SCHEDULED_OPERATIONS = gql`
  query scheduledOperations {
    auth {
      scheduledOperations(service: INTEL, operation: "") {
        id
        operation
        leaseHolder
        lastExecutionTime
        leaseExpiryTime
        executionFrequency
        kwargs
      }
    }
  }
`;

export const CAMPAIGNS = gql`
  query pushnoCampaigns($after: DateTime, $before: DateTime, $product: String) {
    auth {
      pushnoCampaigns(after: $after, before: $before, product: $product) {
        id
        product
        name
        startTs
        endTs
        title
        body
        sentCount
        openCount
      }
    }
  }
`;

export const CAMPAIGN = gql`
  query pushnoCampaign($id: UUID!) {
    auth {
      pushnoCampaign(campaignId: $id) {
        id
        product
        name
        startTs
        endTs
        title
        body
        sentCount
        openCount
        dataPayload
      }
    }
  }
`;

export const POLLS = gql`
  query feedEntities(
    $page: Int
    $pageSize: Int
    $leagueId: UUID
    $zoneType: ContentZoneEnum
    $showHidden: Boolean
  ) {
    auth {
      feedEntities(
        leagueId: $leagueId
        page: $page
        entityType: "survey"
        pageSize: $pageSize
        zoneType: $zoneType
        showHidden: $showHidden
      ) {
        id
        leagueId
        mobilePosition
        survey {
          id
          league {
            name
            id
            __typename
          }
          question
          rank
          startDate
          endDate
          answers {
            id
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const POLL = gql`
  query poll($id: UUID!) {
    auth {
      survey(id: $id) {
        id
        league {
          name
          id
        }
        leagueId
        question
        startDate
        rank
        endDate
        answers {
          id
          answer
        }
      }
    }
  }
`;

export const PUSHNO = gql`
  query scheduledOperation($id: UUID!) {
    auth {
      scheduledOperation(service: AUTH, id: $id) {
        id
        kwargs
        lastExecutionTime
        executionFrequency
        usernames
      }
    }
  }
`;

export const MATCHUPS = gql`
  query matchups($leagueId: UUID!, $startDate: DateTime!, $endDate: DateTime) {
    auth {
      matchups(leagueId: $leagueId, startDate: $startDate, endDate: $endDate) {
        id
        homeTeam {
          id
          fullName
        }
        awayTeam {
          id
          fullName
        }
        startTime
        endTime
      }
    }
  }
`;

export const FEED_ENTITIES = gql`
  query feedEntities(
    $leagueId: UUID
    $entityType: String
    $page: Int
    $pageSize: Int
    $showHidden: Boolean
    $zoneType: ContentZoneEnum
  ) {
    auth {
      feedEntities(
        leagueId: $leagueId
        entityType: $entityType
        page: $page
        pageSize: $pageSize
        showHidden: $showHidden
        zoneType: $zoneType
      ) {
        id
        leagueId
        slugId
        rightRailPosition
        webHomePosition
        carouselPosition
        webPosition
        mobilePosition
        externalJson
        updatedAt
        createdAt
        hidden
        entityType
        survey {
          id
          question
          league {
            id
            name
          }
        }
      }
    }
  }
`;

export const FEED_ZONE_SETTING = gql`
  query feedZoneSetting($leagueId: UUID!, $zoneType: String!) {
    auth {
      feedZoneSetting(leagueId: $leagueId, zoneType: $zoneType) {
        maxLength
      }
    }
  }
`;

export const FEED_ENTITY = gql`
  query feedEntity($id: UUID!) {
    auth {
      feedEntity(id: $id) {
        id
        leagueId
        slugId
        rightRailPosition
        carouselPosition
        webHomePosition
        webPosition
        mobilePosition
        externalJson
        updatedAt
        createdAt
        hidden
      }
    }
  }
`;

export const EVENTS = gql`
  query eventList(
    $leagueName: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    auth {
      events(
        leagueName: $leagueName
        startDate: $startDate
        endDate: $endDate
      ) {
        id
        slugId
        startDate
        homeTeam {
          preferredAbbreviation
        }
        awayTeam {
          preferredAbbreviation
        }
        gameAnalysis
      }
    }
  }
`;

export const FREE_EVENT_OVERRIDES = gql`
  query freeEventOverrides(
    $leagueName: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    auth {
      freeEventOverrides(
        leagueName: $leagueName
        startDate: $startDate
        endDate: $endDate
      ) {
        id
        createdAt
        leagueId
        startAt
        endAt
        events {
          id
          startDate
          slugId
          homeTeam {
            id
            preferredAbbreviation
          }
          awayTeam {
            id
            preferredAbbreviation
          }
        }
      }
    }
  }
`;

export const USER_SUBSCRIPTIONS = gql`
  query userSubscriptions(
    $userEmail: String!
    $product: String!
    $platform: String
  ) {
    auth {
      userSubscriptions(
        userEmail: $userEmail
        product: $product
        platform: $platform
      ) {
        id
        receiptId
        expiration
        source
        purchasePrice
        purchaseTs
        subscriptionLevel {
          id
          name
          expiresTs
        }
      }
    }
  }
`;

export const AFFILIATE_QUERY = gql`
  query affiliateUsers {
    auth {
      affiliateStates(includeNational: true, allStates: true) {
        id
        name
        abbreviation
        offers {
          id
          rank
          operatorId
          affiliateLink
          affiliateLinkWeb
          imageUrl
          bookName
          welcomeOffer
          howItWorks
          limitedOffer
          promoCode
          unpreferredPayment
          preferredPayment
          rating
          staffPick
          startDate
          endDate
          stateId
          json
        }
      }
    }
  }
`;
