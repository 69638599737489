export const US_STATES = [
  { value: "N/A", label: "NULL" },
  { value: "AL", label: "ALABAMA" },
  { value: "AK", label: "ALASKA" },
  { value: "AZ", label: "ARIZONA" },
  { value: "AR", label: "ARKANSAS" },
  { value: "CA", label: "CALIFORNIA" },
  { value: "CO", label: "COLORADO" },
  { value: "CT", label: "CONNECTICUT" },
  { value: "DE", label: "DELAWARE" },
  { value: "DC", label: "DISTRICT OF COLUMBIA" },
  { value: "FL", label: "FLORIDA" },
  { value: "GA", label: "GEORGIA" },
  { value: "HI", label: "HAWAII" },
  { value: "ID", label: "IDAHO" },
  { value: "IL", label: "ILLINOIS" },
  { value: "IN", label: "INDIANA" },
  { value: "IA", label: "IOWA" },
  { value: "KS", label: "KANSAS" },
  { value: "KY", label: "KENTUCKY" },
  { value: "LA", label: "LOUISIANA" },
  { value: "ME", label: "MAINE" },
  { value: "MD", label: "MARYLAND" },
  { value: "MA", label: "MASSACHUSETTS" },
  { value: "MI", label: "MICHIGAN" },
  { value: "MN", label: "MINNESOTA" },
  { value: "MS", label: "MISSISSIPPI" },
  { value: "MO", label: "MISSOURI" },
  { value: "MT", label: "MONTANA" },
  { value: "NE", label: "NEBRASKA" },
  { value: "NV", label: "NEVADA" },
  { value: "NH", label: "NEW HAMPSHIRE" },
  { value: "NJ", label: "NEW JERSEY" },
  { value: "NM", label: "NEW MEXICO" },
  { value: "NY", label: "NEW YORK" },
  { value: "NC", label: "NORTH CAROLINA" },
  { value: "ND", label: "NORTH DAKOTA" },
  { value: "OH", label: "OHIO" },
  { value: "OK", label: "OKLAHOMA" },
  { value: "OR", label: "OREGON" },
  { value: "PA", label: "PENNSYLVANIA" },
  { value: "RI", label: "RHODE ISLAND" },
  { value: "SC", label: "SOUTH CAROLINA" },
  { value: "SD", label: "SOUTH DAKOTA" },
  { value: "TN", label: "TENNESSEE" },
  { value: "TX", label: "TEXAS" },
  { value: "UT", label: "UTAH" },
  { value: "VT", label: "VERMONT" },
  { value: "VA", label: "VIRGINIA" },
  { value: "WA", label: "WASHINGTON" },
  { value: "WV", label: "WEST VIRGINIA" },
  { value: "WI", label: "WISCONSIN" },
  { value: "WY", label: "WYOMING" }
];
