export const LEAGUES = [
  "NCAAF",
  "NFL",
  "XFL",
  "MLB",
  "NBA",
  "NHL",
  "NCAAB",
  "Premier League",
  "Champions League",
  "MLS",
  "La Liga",
  "Serie A",
  "Bundesliga",
  "NONE"
];
export const LEAGUE_MAP = {
  NCAAF: "F97D5B5F5AFD48978FA1EFAC592C9C96",
  NFL: "B8245E1A108644E6B84F26FC8C254EF7",
  XFL: "79FF9E414E9811EAB7220242C0A80002",
  MLB: "38FD1B12FF0740AC8FC22A9A90C00156",
  NBA: "6666728AD5A54DE39DB5B39B4EC758D1",
  NHL: "84D50719A5B74963891D8EC4E057B72E",
  NCAAB: "735CF2194FFC4CA08307ED94853DF175",
  "Premier League": "DE06A08C83E211EAA8870242C0A80002",
  "Champions League": "EAA57F8A83E211EAA8870242C0A80002",
  MLS: "ED4812A383E211EAA8870242C0A80002",
  "La Liga": "EF9DE81883E211EAA8870242C0A80002",
  "Serie A": "F228A94183E211EAA8870242C0A80002",
  Bundesliga: "E75CC35383E211EAA8870242C0A80002",
  ATP: "EDD34123EAA14429AD2DFB7088510027",
  WTA: "843930BDF7524F349A76194C76E1F0FB",
  NONE: null
};

export const LEAGUE_DISPLAY_NAMES = [
  { display: "NFL", value: "NFL" },
  { display: "NCAAF", value: "CFB" },
  { display: "XFL", value: "XFL" },
  { display: "NCAAB", value: "CBK" },
  { display: "NBA", value: "NBA" },
  { display: "MLB", value: "MLB" },
  { display: "NHL", value: "NHL" },
  { display: "Premier League", value: "EPL" },
  { display: "Champions League", value: "UCL" },
  { display: "MLS", value: "MLS" },
  { display: "La Liga", value: "ESP" },
  { display: "Serie A", value: "ITSA" },
  { display: "Bundesliga", value: "DEB" },
  { display: "ATP Tennis", value: "ATP" },
  { display: "WTA Tennis", value: "WTA" }
];

// "NFL", "CFB", "XFL", "CBK", "NBA", "MLB", "NHL", "DEB", "EPL", "ESP", "ITSA", "ATP", "WTA"];

/*
 * Hardcoded for the sake of time, not the best but if these ids ever change,
 * we've got bigger problems than admin pushnos
 */
export const SUBSCRIPTION_LEVELS = [
  { value: "FREE", label: "FREE/EXPIRED", level: 0 },
  {
    value: "39183B2030A011E99CC10242AC150002",
    label: "betql_prem_day",
    level: 1
  },
  {
    value: "3ABE212D30A011E99CC10242AC150002",
    label: "betql_pro_day",
    level: 2
  },
  {
    value: "3B14C64C30A011E99CC10242AC150002",
    label: "betql_vip_day",
    level: 3
  },
  {
    value: "3B5F423E30A011E99CC10242AC150002",
    label: "betql_prem_3day",
    level: 1
  },
  {
    value: "415B47AA30A011E99CC10242AC150002",
    label: "betql_pro_3day",
    level: 2
  },
  {
    value: "41D1B65B30A011E99CC10242AC150002",
    label: "betql_vip_3day",
    level: 3
  },
  {
    value: "422BB3AD991D4480A950F2DE7EC2C798",
    label: "betql_pro_week",
    level: 2
  },
  {
    value: "5730F8D6AA4311E8897A42010A800391",
    label: "betql_prem_month",
    level: 1
  },
  {
    value: "57322084AA4311E8897A42010A800391",
    label: "betql_prem_year",
    level: 1
  },
  {
    value: "57333017AA4311E8897A42010A800391",
    label: "betql_pro_month",
    level: 2
  },
  {
    value: "573423D6AA4311E8897A42010A800391",
    label: "betql_pro_year",
    level: 2
  },
  {
    value: "57350278AA4311E8897A42010A800391",
    label: "betql_pro_six_months",
    level: 2
  },
  {
    value: "5735E6CEAA4311E8897A42010A800391",
    label: "betql_vip_month",
    level: 3
  },
  {
    value: "583360C0AA4311E8897A42010A800391",
    label: "betql_vip_year",
    level: 3
  },
  {
    value: "74BAC6405B0E4EDD90347A9076091239",
    label: "betql_prem_three_months",
    level: 1
  },
  {
    value: "9AFE664F444B40128E07F805C66D3A7C",
    label: "betql_pro_three_months",
    level: 2
  },
  {
    value: "A899E6DFCFAB4DEBA3845C8D4F6425BD",
    label: "betql_vip_week",
    level: 3
  },
  {
    value: "F16B8F3C68934940976514C569883AA0",
    label: "betql_prem_week",
    level: 1
  },
  {
    value: "F1B639FCCC094278859A3C0E23BC5CD8",
    label: "betql_vip_three_months",
    level: 3
  },
  {
    value: "2112099E2C7A4506A25D2696A5453660",
    label: "web_betql_vip_quarter",
    level: 3
  },
  {
    value: "322163C3E72E42F0893DF5A96441340E",
    label: "web_betql_pro_year",
    level: 2
  },
  {
    value: "34CB82D2D2B24358B771D1857FA900A8",
    label: "web_betql_vip_year",
    level: 3
  },
  {
    value: "667F825E56204B74A1BF4A1089357F9A",
    label: "web_betql_pro_quarter",
    level: 2
  },
  {
    value: "71241406915A432BB9F10C331DFDD985",
    label: "web_betql_sharp_year",
    level: 4
  },
  {
    value: "8A7406BB68344DE0A1A3A863CB4E006D",
    label: "web_betql_premium_quarter",
    level: 1
  },
  {
    value: "C80221072D4E428AA689643047FAED48",
    label: "web_betql_sharp_quarter",
    level: 4
  },
  {
    value: "D099545E1F53406C8A51C4FE09A1020B",
    label: "web_betql_premium_week",
    level: 1
  },
  {
    value: "FA055B1317E5478D8F6D1E1ECAC16220",
    label: "web_betql_sharp_week",
    level: 4
  },
  {
    value: "FD5A501E4D5A475080C70118A3AAA51F",
    label: "web_betql_premium_year",
    level: 1
  },
  {
    value: "446130D8A2794C7D8824E24605E6DF1B",
    label: "web_betql_pro_week",
    level: 2
  },
  {
    value: "45125737754B4974AC3179F41199792D",
    label: "web_betql_vip_week",
    level: 3
  }
];
