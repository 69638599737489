import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import "moment-timezone";
import Moment from "react-moment";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckBox from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";
import AddBox from "@material-ui/icons/AddBox";
import Refresh from "@material-ui/icons/Refresh";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BET_SCHEDULED_OPERATIONS,
  INTEL_SCHEDULED_OPERATIONS
} from "../../../queries";

import {
  BET_RESET_SCHEDULED_OPERATION,
  INTEL_RESET_SCHEDULED_OPERATION
} from "../../../mutations";

import { calcuate_breakdown_from_run_frequency } from "../../../helpers";
import { ButtonGroup } from "@material-ui/core";
import { RefreshRounded } from "@material-ui/icons";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  grow: {
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    cursor: "pointer"
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  textinput: {
    margin: theme.spacing(1)
  }
});

class ScheduledOperations extends Component {
  constructor(props) {
    super(props);
    this.state = { service: "BET" };
  }

  _seconds_to_display_str(secs) {
    const {
      days,
      hours,
      minutes,
      seconds
    } = calcuate_breakdown_from_run_frequency(secs);

    let interval_string = "";
    if (days > 0) {
      if (days === 1) {
        interval_string = interval_string + "1 Day";
      } else {
        interval_string = interval_string + days + " Days";
      }
    }
    if (hours > 0) {
      if (hours === 1) {
        interval_string = interval_string + "1 Hour";
      } else {
        interval_string = interval_string + hours + " Hours";
      }
    }
    if (minutes > 0) {
      if (minutes === 1) {
        interval_string = interval_string + " 1 Min";
      } else {
        interval_string = interval_string + " " + minutes + " Mins";
      }
    }
    if (seconds > 0) {
      if (seconds === 1) {
        interval_string = interval_string + " 1 Sec";
      } else {
        interval_string = interval_string + " " + seconds + " Secs";
      }
    }
    return interval_string;
  }

  _state_filter(state_filter) {
    if (state_filter && state_filter.length > 0) {
      return state_filter.join();
    } else {
      return "ALL";
    }
  }

  _single_run_component(is_single_run) {
    if (is_single_run) {
      return <CheckBox />;
    } else {
      return <CheckBoxOutlineBlank />;
    }
  }

  _user_cnt(usernames) {
    if (usernames && usernames.length > 0) {
      return usernames.length;
    } else {
      return "All";
    }
  }

  _renderNextStartTime(row) {
    const runTime = moment
      .utc(row.lastExecutionTime)
      .add(row.executionFrequency, "seconds");

    if (moment().isAfter(runTime)) {
      return "Running";
    }

    return (
      <Moment
        format="ddd MM-DD hh:mm a"
        add={{ seconds: row.executionFrequency }}
      >
        {moment.utc(row.lastExecutionTime).local()}
      </Moment>
    );
  }

  render() {
    const { classes, history } = this.props;
    const { service } = this.state;

    return (
      <React.Fragment>
        <Query
          query={
            service === "BET"
              ? BET_SCHEDULED_OPERATIONS
              : INTEL_SCHEDULED_OPERATIONS
          }
          fetchPolicy="cache-and-network"
          errorPolicy="all"
        >
          {resp => {
            const { loading, errors, data, refetch } = resp;
            if (loading) {
              return (
                <React.Fragment>
                  <Typography variant="h4" className={classes.grow}>
                    Loading Scheduled Operations
                  </Typography>
                  <CircularProgress />
                </React.Fragment>
              );
            }
            if (errors) {
              return (
                <Typography variant="h4" className={classes.grow}>
                  "Error Fetching Scheduled Operations.";
                </Typography>
              );
            }
            const scheduledOperations =
              (data && data.auth && data.auth.scheduledOperations) || [];

            scheduledOperations.sort(function(a, b) {
              return a.operation < b.operation;
            });

            return (
              <div>
                <Toolbar>
                  <Typography variant="h4" className={classes.grow}>
                    Scheduled Operations
                  </Typography>
                  <span style={{ width: "200px", padding: "10px" }}>
                    <ButtonGroup
                      fullWidth
                      aria-label="Services"
                      color="primary"
                      disabled={loading}
                    >
                      <Button
                        key={0}
                        onClick={() => {
                          this.setState({ service: "BET" }, () => {
                            refetch();
                          });
                        }}
                        color="primary"
                      >
                        BETQL
                      </Button>
                      <Button
                        key={1}
                        onClick={() => {
                          this.setState({ service: "INTEL" }, () => {
                            refetch();
                          });
                        }}
                        color={service === "INTEL" ? "primary" : ""}
                      >
                        INTELQL
                      </Button>
                    </ButtonGroup>
                  </span>

                  <Button
                    variant="contained"
                    color="primary"
                    align="right"
                    onClick={() => refetch()}
                  >
                    <Refresh />
                  </Button>
                </Toolbar>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Operation</TableCell>
                        <TableCell>Lease Holder</TableCell>
                        <TableCell>Last Run</TableCell>
                        <TableCell>Next Run</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Run Now (Within 1 minute)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scheduledOperations.map(row => {
                        return (
                          <TableRow className={classes.row} hover key={row.id}>
                            <TableCell>{row.operation}</TableCell>
                            <TableCell>{row.leaseHolder}</TableCell>
                            <TableCell>
                              <Moment format="ddd MM-DD hh:mm a">
                                {moment.utc(row.lastExecutionTime).local()}
                              </Moment>
                            </TableCell>
                            <TableCell>
                              {this._renderNextStartTime(row)}
                            </TableCell>
                            <TableCell>
                              {this._seconds_to_display_str(
                                row.executionFrequency
                              )}
                            </TableCell>
                            <Mutation
                              mutation={
                                service === "BET"
                                  ? BET_RESET_SCHEDULED_OPERATION
                                  : INTEL_RESET_SCHEDULED_OPERATION
                              }
                            >
                              {(mutation_query, mutation_resp) => {
                                if (mutation_resp.error) {
                                  if (
                                    mutation_resp.error.graphQLErrors.length > 0
                                  ) {
                                    return (
                                      <p>
                                        Error:{" "}
                                        {
                                          mutation_resp.error
                                            .graphQLErrors[0][0].title
                                        }
                                      </p>
                                    );
                                  }
                                  return (
                                    <p>Error: {mutation_resp.error + ""}</p>
                                  );
                                } else {
                                  return (
                                    <TableCell>
                                      <Button
                                        color="secondary"
                                        onClick={async event => {
                                          event.stopPropagation();
                                          await mutation_query({
                                            variables: {
                                              id: row.id,
                                              leaseExpiryTime: new Date(2000, 1, 1),
                                              lastExecutionTime: new Date(2000, 1, 1)
                                            }
                                          });
                                          refetch();
                                        }}
                                      >
                                        <RefreshRounded />
                                      </Button>
                                    </TableCell>
                                  );
                                }
                              }}
                            </Mutation>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            );
          }}
        </Query>
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(ScheduledOperations));
