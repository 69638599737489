import React, { Component } from "react";
import "typeface-roboto";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import decode from "jwt-decode";
import { withRouter } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  logout: {
    "margin-left": theme.spacing(3)
  }
});

class Header extends Component {
  _getPems(lvls) {
    let retval = "DENIED";
    Object.keys(lvls).forEach(lvl => {
      if (lvl === "admin_write") {
        retval = "write";
      } else if (lvl === "admin_read") {
        retval = "read";
      }
    });
    return retval;
  }

  render() {
    const token = localStorage.getItem("token");
    let loginSpecificHeader;
    if (token != null) {
      const claims = decode(token);
      loginSpecificHeader = (
        <ApolloConsumer>
          {client => (
            <Toolbar>
              <Typography variant="h6" color="inherit" align="right">
                {claims.email} - {this._getPems(claims.lvl)}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                className={this.props.classes.logout}
                onClick={() => {
                  localStorage.removeItem("token");
                  client.resetStore();
                  this.props.history.push("/");
                }}
              >
                Logout
              </Button>
            </Toolbar>
          )}
        </ApolloConsumer>
      );
    } else {
      loginSpecificHeader = (
        <Typography variant="h6" color="inherit" align="right">
          Please Login
        </Typography>
      );
    }
    return (
      <div className={this.props.classes.grow}>
        <AppBar position="fixed" className={this.props.classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={this.props.classes.grow}
              align="left"
            >
              QLGG Admin Portal
            </Typography>
            {loginSpecificHeader}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Header));
