import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import ButtonGroup from "@material-ui/core/ButtonGroup";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";

import {
  UPSERT_FREE_EVENT_OVERRIDE,
  DELETE_FREE_EVENT_OVERRIDE,
  UPDATE_FREE_EVENT
} from "../../../mutations";

import { FREE_EVENT_OVERRIDES } from "../../../queries";

const LEAGUE_NAMES = [
  "NFL",
  "CFB",
  "XFL",
  "CBK",
  "NBA",
  "MLB",
  "NHL",
  "EPL",
  "UCL",
  "MLS",
  "ESP",
  "ITSA",
  "DEB",
  "ATP",
  "WTA"
];

const STATE_DATE_TEXT = moment().format("YYYY-MM-DD");
const END_DATE_TEXT = moment().format("YYYY-MM-DD");

const START_DATETIME = moment();
const END_DATETIME = moment().add(1, "days");

export default function FreeGamesOverrideComponent() {
  console.log("FreeGamesOverrideComponent");

  const startDateText = STATE_DATE_TEXT;
  const endDateText = END_DATE_TEXT;

  const COLUMNS = [
    {
      title: "League",
      field: "league",
      editable: "never",
      headerStyle: { maxWidth: 50 },
      cellStyle: { maxWidth: 50 }
    },
    {
      title: "Manual Override From",
      field: "validFrom",
      render: rawData =>
        rawData && rawData.validFrom ? rawData.validFrom : startDateText,
      headerStyle: { maxWidth: 100 },
      cellStyle: { maxWidth: 100 }
    },
    {
      title: "Manual Override To",
      field: "validTo",
      render: rawData =>
        rawData && rawData.validTo ? rawData.validTo : endDateText,
      headerStyle: { maxWidth: 100 },
      cellStyle: { maxWidth: 100 }
    },
    {
      title: "Slug",
      field: "slugId",
      editable: "onAdd"
    },
    {
      title: "Game Date",
      field: "gameDate",
      editable: "never",
      defaultSort: "desc"
    },
    {
      title: "Away",
      field: "away",
      editable: "never",
      headerStyle: { maxWidth: 50 },
      cellStyle: { maxWidth: 50 }
    },
    {
      title: "Home",
      field: "home",
      editable: "never",
      headerStyle: { maxWidth: 50 },
      cellStyle: { maxWidth: 50 },
      render: rawData => (rawData && rawData.home ? "@" + rawData.home : "")
    }
  ];

  const [leagueName, setLeagueName] = useState("NFL");
  const [dataRows, setDataRows] = useState([]);
  const [messageData, setMessageData] = useState({ message: "", type: "" });

  const { data, loading, refetch } = useQuery(FREE_EVENT_OVERRIDES, {
    variables: {
      leagueName,
      startDate: START_DATETIME,
      endDate: END_DATETIME
    },
    fetchPolicy: "cache-and-network"
  });

  console.log("Request is loading", loading);

  const [
    deleteFreeEventOverride,
    { loading: loadingDeleteFreeEventOverride }
  ] = useMutation(DELETE_FREE_EVENT_OVERRIDE);

  const [
    upsertFreeEventOverride,
    { loading: loadingFreeEventOverride }
  ] = useMutation(UPSERT_FREE_EVENT_OVERRIDE);
  const [updateFreeGame, { loading: loadingFreeGameLoading }] = useMutation(
    UPDATE_FREE_EVENT
  );

  function showMessage(message, type) {
    setMessageData({ message, type });

    if (message) {
      setTimeout(() => {
        setMessageData({ message: "", type: "" });
      }, 5000);
    }
  }

  useEffect(() => {
    // only refetch after it's completed
    if (
      !loadingFreeGameLoading ||
      !loadingFreeEventOverride ||
      !loadingDeleteFreeEventOverride
    ) {
      // refech
      showMessage("Loading, please wait...", "primary");
      refetch();
    } else {
    }
  }, [
    loadingFreeGameLoading,
    loadingFreeEventOverride,
    loadingDeleteFreeEventOverride,
    refetch
  ]);

  useEffect(() => {
    function toDataRows(updatedFreeEventOverrides) {
      const _dataRows = [];
      updatedFreeEventOverrides.forEach(freeEventOverride => {
        if (freeEventOverride.length !== 0) {
          if (freeEventOverride.events.length === 0) {
            let row = {
              league: leagueName,
              freeEventOverrideId: freeEventOverride.id,
              validFrom: moment(freeEventOverride.startAt).format("YYYY-MM-DD"),
              validTo: moment(freeEventOverride.endAt).format("YYYY-MM-DD"),
              date: null
            };

            _dataRows.push(row);
          } else {
            freeEventOverride.events.forEach(event => {
              let row = {
                league: leagueName,
                gameDate: moment(event.startDate).format("YYYY-MM-DD"),
                freeEventOverrideId: freeEventOverride.id,
                validFrom: moment(freeEventOverride.startAt).format(
                  "YYYY-MM-DD"
                ),
                validTo: moment(freeEventOverride.endAt).format("YYYY-MM-DD"),
                date: moment(event.startDate).format("YYYY-MM-DD"),
                home: event.homeTeam
                  ? event.homeTeam.preferredAbbreviation
                  : event.homeTeamId,
                away: event.awayTeam
                  ? event.awayTeam.preferredAbbreviation
                  : event.awayTeamId,
                slugId: event.slugId
              };

              _dataRows.push(row);
            });
          }
        }
      });

      return _dataRows;
    }

    if (!loading) {
      if (data && data.auth && data.auth.freeEventOverrides) {
        setDataRows(toDataRows(data.auth.freeEventOverrides));
        showMessage("", "");
      }
    }
  }, [data, loading, leagueName]);

  /* https://github.com/mbrn/material-table/issues/615 */
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ButtonGroup
          fullWidth
          aria-label="Sports"
          color="primary"
          disabled={loading}
        >
          {LEAGUE_NAMES.map((name, index) => (
            <Button
              key={index}
              onClick={() => {
                setLeagueName(name);
              }}
            >
              {name}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <small>
            <ul>
              <li>
                For NFL/NCAAF, the "Manual Override From/To" date ranges should
                be weekly Thu to Wed
              </li>
              <li>
                For other daily sport (NBA, MLB), starting and ending date are
                usually the same
              </li>
              <li>
                Date range can be longer for special periods like NBA playoffs,
                so that we can control the free games precisouly during this
                period
              </li>
            </ul>
          </small>
        </Typography>
      </Grid>
      <Grid container direction="column">
        <MaterialTable
          title="Free Games"
          columns={COLUMNS}
          data={dataRows}
          isLoading={messageData.message !== ""}
          editable={{
            onRowAdd: newData =>
              new Promise(async (resolve, reject) => {
                if (newData.validFrom && newData.validTo) {
                  const startAt = moment(newData.validFrom);
                  const endAt = moment(newData.validTo);
                  await upsertFreeEventOverride({
                    variables: {
                      leagueName,
                      startAt,
                      endAt
                    }
                  });
                }

                if (newData.slugId) {
                  await updateFreeGame({
                    variables: {
                      slugId: newData.slugId,
                      freeGame: true
                    }
                  });
                }

                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                console.log("OnRowUpdate");

                if (newData.validFrom && newData.validTo) {
                  const startAt = moment(newData.validFrom);
                  const endAt = moment(newData.validTo);

                  await upsertFreeEventOverride({
                    variables: {
                      leagueName,
                      startAt,
                      endAt
                    }
                  });
                }

                if (newData.slugId) {
                  await updateFreeGame({
                    variables: {
                      slugId: newData.slugId,
                      freeGame: true
                    }
                  });
                }

                resolve();
              }),
            onRowDelete: oldData =>
              new Promise(async (resolve, reject) => {
                console.log(oldData);
                if (oldData.slugId) {
                  await updateFreeGame({
                    variables: {
                      slugId: oldData.slugId.trim(),
                      freeGame: false
                    }
                  });
                } else {
                  await deleteFreeEventOverride({
                    variables: {
                      id: oldData.freeEventOverrideId
                    }
                  });
                }
                resolve();
              })
          }}
        />
      </Grid>
    </React.Fragment>
  );
}
