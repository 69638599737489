import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { LOGIN } from "../../mutations";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import decode from "jwt-decode";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up(400 + theme.spacing(5))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing(3)
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      unauthorized: false
    };
  }

  _handle_login_submission = async send_mutate_function => {
    let returned_data;
    const { username, password } = this.state;
    try {
      returned_data = await send_mutate_function({
        variables: {
          username: username,
          password: password
        }
      });
    } catch (err) {
      console.log("error : " + err);
    }
    if (returned_data) {
      const claims = decode(returned_data.data.login.token);

      if (!("admin_write" in claims.lvl) && !("admin_read" in claims.lvl)) {
        this.setState({ unauthorized: true });
      } else {
        localStorage.setItem("token", returned_data.data.login.token);
        this.props.history.push("/");
      }
    }
  };

  _display_error(resp) {
    if (resp.error) {
      if (resp.error.graphQLErrors.length > 0) {
        return <p>Error: {resp.error.graphQLErrors[0][0].title}</p>;
      }
      return <p>Error: {resp.error + ""}</p>;
    }
  }

  _unauthorized_error(unauthroized) {
    if (unauthroized) {
      return <p>Error: User has no admin authorizations, denied.</p>;
    }
  }

  render() {
    const { unauthorized, username, password } = this.state;
    return (
      <main className={this.props.classes.main}>
        <CssBaseline />
        <Paper className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <LockIcon />
          </Avatar>
          <Mutation mutation={LOGIN}>
            {(login_func, resp) => (
              <form
                className={this.props.classes.form}
                onSubmit={event => {
                  event.preventDefault();
                  this._handle_login_submission(login_func);
                }}
              >
                {this._display_error(resp)}
                {this._unauthorized_error(unauthorized)}
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={username}
                    onChange={ev => {
                      this.setState({ username: ev.target.value });
                    }}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={ev => {
                      this.setState({ password: ev.target.value });
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.submit}
                >
                  Login
                </Button>
              </form>
            )}
          </Mutation>
        </Paper>
        <small>
          <div>v1.0.1</div>
        </small>
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(Login));
