import React, { useState, useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

import {
  Dialog,
  DialogTitle,
  Grid,
  FormControl,
  ButtonGroup,
  Button,
  TextareaAutosize
} from "@material-ui/core";

import MaterialTable from "material-table";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";

import { UPDATE_GAME_ANALYSIS } from "../../../mutations";

import { EVENTS } from "../../../queries";

const LEAGUE_NAMES = [
  "NFL",
  "CFB",
  "XFL",
  "CBK",
  "NBA",
  "MLB",
  "NHL",
  "EPL",
  "UCL",
  "MLS",
  "ESP",
  "ITSA",
  "DEB",
  "ATP",
  "WTA"
];

const START_DATETIME = moment();
const END_DATETIME = moment().add(7, "days");

function formatText(text) {
  return text ? text.replace(/\n/g, "<br/>") : "";
}

function unformatText(text) {
  return text ? text.replace(/<br\/>/g, "\n") : "";
}

function GameAnalysisDialog({ selectedEvent, handleGameAnalysisUpdate }) {
  const [gameAnalysisText, setGameAnalysisText] = useState(
    unformatText(selectedEvent.gameAnalysis)
  );

  console.log("selectedEvent", selectedEvent);

  return (
    <Dialog
      fullWidth={true}
      onClose={() => {
        console.log("close");
      }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <DialogTitle id="simple-dialog-title">Update Game Analysis</DialogTitle>
      <div style={{ padding: 20 }}>
        <TextareaAutosize
          style={{ width: "100%", height: "100%" }}
          aria-label="minimum height"
          rows={10}
          value={gameAnalysisText}
          placeholder="Enter analysis text"
          onChange={e => {
            e.preventDefault();
            setGameAnalysisText(e.target.value);
          }}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ButtonGroup>
          <Button
            color="secondary"
            onClick={() => {
              handleGameAnalysisUpdate(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              selectedEvent.gameAnalysis = formatText(gameAnalysisText);
              handleGameAnalysisUpdate(selectedEvent);
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
}

export default function GameAnalyisComponent() {
  const COLUMNS = [
    {
      title: "Time",
      field: "startDate",
      render: rawData => {
        return moment(rawData.startDate).format("YYYY-MM-DD");
      },
      headerStyle: { maxWidth: 100 },
      cellStyle: { maxWidth: 100 }
    },
    {
      title: "Slug",
      field: "slugId",
      editable: "onAdd"
    },
    {
      title: "Away",
      field: "awayTeam",
      editable: "never",
      headerStyle: { maxWidth: 50 },
      cellStyle: { maxWidth: 50 },
      render: rawData => rawData.awayTeam.preferredAbbreviation
    },
    {
      title: "Home",
      field: "homeTeam",
      editable: "never",
      headerStyle: { maxWidth: 50 },
      cellStyle: { maxWidth: 50 },
      render: rawData => "@" + rawData.homeTeam.preferredAbbreviation
    },
    {
      title: "Analysis",
      field: "gameAnalysis",
      editable: "never",
      headerStyle: { minWidth: 200 },
      cellStyle: { minWidth: 200 },
      render: rawData => {
        return (
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              console.log(rawData);
              setSelectedEvent(rawData);
            }}
          >
            {rawData.gameAnalysis
              ? rawData.gameAnalysis.substring(0, 80) + "..."
              : "UPDATE"}
          </a>
        );
      }
    }
  ];

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [leagueName, setLeagueName] = useState("NFL");
  const [dataRows, setDataRows] = useState([]);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [startDate, setStartDate] = useState(START_DATETIME);
  const [endDate, setEndDate] = useState(END_DATETIME);

  const { data, loading, refetch } = useQuery(EVENTS, {
    variables: {
      leagueName,
      startDate,
      endDate
    },
    fetchPolicy: "cache-and-network"
  });

  const [
    updateGameAnalysis,
    { loading: loadingUpdateGameAnalysis }
  ] = useMutation(UPDATE_GAME_ANALYSIS);

  function showMessage(message, type) {
    setMessageData({ message, type });

    if (message) {
      setTimeout(() => {
        setMessageData({ message: "", type: "" });
      }, 5000);
    }
  }

  function handleGameAnalysisUpdate(updatedEvent) {
    setSelectedEvent(null);

    if (updatedEvent) {
      updateGameAnalysis({
        variables: {
          slugId: updatedEvent.slugId,
          gameAnalysis: updatedEvent.gameAnalysis
            ? updatedEvent.gameAnalysis.trim()
            : ""
        }
      });
    }
  }

  useEffect(() => {
    // only refetch after it's completed
    if (!loadingUpdateGameAnalysis) {
      showMessage("Loading, please wait...", "primary");
      refetch();
    }
  }, [loadingUpdateGameAnalysis]);

  useEffect(() => {
    if (!loading) {
      if (data && data.auth && data.auth.events && data.auth.events.length) {
        setDataRows(data.auth.events);
        showMessage("", "");
      }
    }
  }, [data, loading, leagueName]);

  //  console.log("re-rendering");

  return (
    <React.Fragment>
      {selectedEvent ? (
        <GameAnalysisDialog
          selectedEvent={selectedEvent}
          handleGameAnalysisUpdate={handleGameAnalysisUpdate}
        />
      ) : null}

      <Grid item xs={12}>
        <ButtonGroup
          fullWidth
          aria-label="Sports"
          color="primary"
          disabled={loading}
        >
          {LEAGUE_NAMES.map((name, index) => (
            <Button
              key={index}
              onClick={() => {
                setLeagueName(name);
              }}
            >
              {name}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid>
        <FormControl>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              id="datetime-start"
              label="Start"
              value={startDate}
              onChange={date => {
                setStartDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <FormControl>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              id="datetime-to"
              label="To"
              value={endDate}
              onChange={date => {
                setEndDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
      <Grid container direction="column">
        <MaterialTable
          title="Games"
          columns={COLUMNS}
          data={dataRows}
          isLoading={messageData.message !== ""}
        />
      </Grid>
    </React.Fragment>
  );
}
