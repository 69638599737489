import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withApollo } from "react-apollo";
import { LEAGUES, LEAGUE_MAP } from "../../../constants";

const styles = theme => ({
  textinput: {
    width: "100px"
  }
});

const LeaguePicker = props => {
  const { classes, onChangeMethod, selectedId } = props;
  const menuItems = LEAGUES.map(league => (
    <MenuItem key={LEAGUE_MAP[league]} value={LEAGUE_MAP[league]}>
      {league}
    </MenuItem>
  ));
  return (
    <FormControl className={classes.textinput}>
      <InputLabel shrink>League</InputLabel>
      <Select
        value={selectedId}
        onChange={e => {
          onChangeMethod(e);
        }}
        input={<Input name="league" />}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default withApollo(withStyles(styles)(LeaguePicker));
