import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Navbar from "./Navbar";
import Pushnos from "./Pushnos";
import Pushno from "./Pushno";
import Subs from "./Subs";
import Campaign from "./Pushnos/campaign";
import FreeGames from "./FreeGames";
import GameAnalysis from "./GameAnalysis";
import ScheduledOperations from "./ScheduledOperations";

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
});

class Dashboard extends Component {
  render() {
    const { classes } = this.props;

    const token = localStorage.getItem("token");

    if (token == null) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={classes.root}>
        <Navbar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Route exact path="/pushes" component={Pushnos} />
          <Route exact path="/push/:id?" component={Pushno} />

          <Route exact path="/campaign/:id" component={Campaign} />
          <Route exact path="/free-games" component={FreeGames} />
          <Route
            exact
            path="/scheduled-operations"
            component={ScheduledOperations}
          />
          <Route exact path="/game-analysis" component={GameAnalysis} />
          <Route exact path="/sub" component={Subs} />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Dashboard));
