export const calcuate_breakdown_from_run_frequency = seconds => {
  const days = Math.floor(seconds / (60 * 60 * 24));
  seconds = seconds - days * (60 * 60 * 24);
  const hours = Math.floor(seconds / (60 * 60));
  seconds = seconds - hours * (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds = seconds - minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};

export function getLeague(hasPrismicData, prismicObj, survey) {
  if (survey) {
    return survey.league ? survey.league.name : "--";
  } else {
    return hasPrismicData && prismicObj.league
      ? prismicObj.league.toUpperCase()
      : "--";
  }
}

export function getFeedEntityTitle(feedEntity, prismicObj) {
  if (prismicObj && prismicObj.title && prismicObj.title[0]) {
    return prismicObj.title[0].text;
  } else if (feedEntity && feedEntity.survey) {
    return feedEntity.survey.question;
  } else {
    return "Error with Entity!";
  }
}
