import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import MomentUtils from "@date-io/moment";

import { CAMPAIGNS } from "../../../queries";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  grow: {
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    cursor: "pointer"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  paper: {
    position: "absolute",
    width: theme.spacing(120),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  textinput: {
    margin: theme.spacing(1)
  }
});

class PushHistoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "bet",
      after: moment().startOf("day"),
      before: moment().endOf("day")
    };
  }

  render() {
    const { classes, history } = this.props;
    const { after, before, product } = this.state;
    return (
      <Query
        query={CAMPAIGNS}
        variables={{ product, after, before }}
        fetchPolicy="cache-and-network"
        errorPolicy="all"
      >
        {resp => {
          const { loading, errors, data, refetch } = resp;
          if (loading) {
            return (
              <Typography variant="h4" className={classes.grow}>
                Loading Push Notification History
              </Typography>
            );
          }
          if (errors) {
            return (
              <Typography variant="h4" className={classes.grow}>
                Error Fetching Push Notification History
              </Typography>
            );
          }
          const pushnoCampaigns =
            (data && data.auth && data.auth.pushnoCampaigns) || [];
          return (
            <React.Fragment>
              <Typography variant="h4" className={classes.grow}>
                Push Notification History
              </Typography>
              <Toolbar>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="page-type">Product: </InputLabel>
                  <Select
                    label="Product:"
                    value={product}
                    className={classes.grow}
                    onChange={e => {
                      this.setState({ product: e.target.value });
                      refetch();
                    }}
                    input={<Input name="product" />}
                  >
                    <MenuItem value="bet">Bet</MenuItem>
                    <MenuItem value="squad">Squad</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      id="datetime-local"
                      label="Sent from:"
                      value={after}
                      className={classes.grow}
                      onChange={date => {
                        this.setState({ after: date.toISOString() });
                        refetch();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      id="datetime-local"
                      label="Sent "
                      value={before}
                      className={classes.grow}
                      onChange={date => {
                        this.setState({ before: date.toISOString() });
                        refetch();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Toolbar>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Campaign Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Start</TableCell>
                      <TableCell>Send Time</TableCell>
                      <TableCell>Messages Sent</TableCell>
                      <TableCell>Open %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pushnoCampaigns
                      .filter(
                        row =>
                          !row.title.includes(
                            "game is starting starts in 15 minutes!"
                          )
                      )
                      .sort((rowA, rowB) => {
                        if (moment(rowA.startTs).isAfter(rowB.startTs)) {
                          return -1;
                        }
                        if (moment(rowA.startTs).isBefore(rowB.startTs)) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(row => {
                        const {
                          id,
                          name,
                          startTs,
                          endTs,
                          title,
                          body,
                          sentCount,
                          openCount
                        } = row;
                        return (
                          <TableRow
                            className={classes.row}
                            hover
                            key={row.id}
                            onClick={() => history.push("/campaign/" + id)}
                          >
                            <TableCell>{product}</TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{title}</TableCell>
                            <TableCell>{body}</TableCell>
                            <TableCell>
                              {moment
                                .utc(startTs)
                                .local()
                                .format("ddd MM-DD hh:mm a")}
                            </TableCell>
                            <TableCell>
                              {endTs
                                ? moment
                                    .duration(moment(startTs).diff(endTs))
                                    .humanize()
                                : moment
                                    .duration(moment.utc(startTs).diff())
                                    .humanize()}
                            </TableCell>
                            <TableCell>{sentCount}</TableCell>
                            <TableCell>
                              {(sentCount
                                ? (100 * openCount) / sentCount
                                : 0
                              ).toFixed(2)}{" "}
                              %
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Paper>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(withRouter(PushHistoryList));
