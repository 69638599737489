import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  Button,
  CircularProgress,
  Grid,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import Moment from "react-moment";
import moment from "moment";

import { USER_SUBSCRIPTIONS } from "../../../queries";
import { ADD_USER_SUBSCRIPTION } from "../../../mutations";

const subscriptionLevels = [
  // "betql_prem_week",
  // "betql_pro_week",
  // "betql_vip_week",
  // "betql_prem_three_months",
  // "betql_pro_three_months",
  // "betql_vip_three_months",
  // "web_betql_prem_week",
  // "web_betql_pro_week",
  // "web_betql_vip_week",
  // "web_betql_sharp_week",
  // "web_betql_prem_quarter",
  // "web_betql_pro_quarter",
  // "web_betql_vip_quarter",
  // "web_betql_sharp_quarter",
  // "web_betql_prem_year",
  // "web_betql_pro_year",
  // "web_betql_vip_year",
  // "web_betql_sharp_year",
  "admin_write",
  "admin_read",
  "admin_publish"
];

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3)
  },
  dropdown: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  loading: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(6)
  },
  searchButton: {
    marginRight: theme.spacing(6)
  },
  topLabelText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4)
  }
}));

export default function Subs() {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState([false, null]);
  const [userEmail, setUserEmail] = useState("");
  const [subscription, setSubscription] = useState(subscriptionLevels[0]);
  const [expiration, setExpiration] = useState(moment().add(7, "d"));
  const [platform, setPlatform] = useState("mobile");

  const [
    getUserSubscriptions,
    { data, loading: getSubsLoading, error: getUserSubscriptionsError }
  ] = useLazyQuery(USER_SUBSCRIPTIONS);

  const [
    addUserSubscription,
    {
      data: addUserSubscriptionResult,
      loading: addSubLoading,
      error: addUserSubscriptionError
    }
  ] = useMutation(ADD_USER_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: USER_SUBSCRIPTIONS,
        variables: { userEmail, product: "all", platform },
        fetchPolicy: "network-only"
      }
    ],
    awaitRefetchQueries: true
  });

  const columns = [
    {
      disableClick: true,
      emptyValue: "N/A",
      title: "Subscription",
      field: "subscriptionLevel.name"
    },
    {
      disableClick: true,
      emptyValue: "N/A",
      title: "Receipt ID",
      field: "receiptId"
    },
    {
      disableClick: true,
      emptyValue: "N/A",
      title: "Source",
      field: "source"
    },
    {
      disableClick: true,
      emptyValue: "N/A",
      title: "Purchased",
      field: "purchaseTs",
      render: rowData => (
        <Moment format="ddd MM-DD hh:mm a">
          {moment.utc(rowData.purchaseTs).local()}
        </Moment>
      )
    },
    {
      disableClick: true,
      emptyValue: "N/A",
      title: "Expiration",
      field: "expiration",
      render: rowData => (
        <Moment format="ddd MM-DD hh:mm a">
          {moment.utc(rowData.expiration).local()}
        </Moment>
      )
    },
    {
      disableClick: true,
      emptyValue: "0.00",
      title: "Pruchase Price",
      field: "subscriptionLevel.purchasePrice"
    }
  ];

  useEffect(() => {
    if (
      addUserSubscriptionResult &&
      addUserSubscriptionResult.auth.createUserSubscription
    ) {
      setSnackbarOpen([true, `Added ${subscription} to user ${userEmail}!`]);
    }
  }, [addUserSubscriptionResult, subscription, userEmail]);

  useEffect(() => {
    if (addUserSubscriptionError && addUserSubscriptionError.graphQLErrors[0]) {
      setSnackbarOpen([
        true,
        `Error: ${addUserSubscriptionError.graphQLErrors[0][0].code} ${addUserSubscriptionError.graphQLErrors[0][0].title}!`
      ]);
    }
  }, [addUserSubscriptionError]);

  useEffect(() => {
    if (
      getUserSubscriptionsError &&
      getUserSubscriptionsError.graphQLErrors[0]
    ) {
      setSnackbarOpen([
        true,
        `Error: ${getUserSubscriptionsError.graphQLErrors[0][0].code} ${getUserSubscriptionsError.graphQLErrors[0][0].title} - ${userEmail}!`
      ]);
    }
  }, [getUserSubscriptionsError, userEmail]);

  useEffect(() => {
    if (subscription.includes("week")) {
      setExpiration(moment().add(7, "d"));
    } else if (subscription.includes("three_months")) {
      setExpiration(moment().add(3, "M"));
    }
  }, [subscription]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key={"top,center"}
        open={snackbarOpen[0]}
        onClose={() => setSnackbarOpen([false, null])}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{snackbarOpen[1]}</span>}
      />
      <Typography variant="h6">User Account Management</Typography>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <TextField
            autoFocus
            label="User Email"
            value={userEmail}
            onChange={e => {
              setUserEmail(e.target.value);
            }}
            margin="normal"
          />
        </Grid>
        <Grid item className={classes.dropdown}>
          <FormControl>
            <InputLabel>Platform: </InputLabel>
            <Select
              label="Platform:"
              value={platform}
              onChange={e => {
                setPlatform(e.target.value);
              }}
              input={<Input name="platform" />}
            >
              {["mobile", "web", "admin"].map(p => (
                <MenuItem key={p} value={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.searchButton}>
          {getSubsLoading ? (
            <CircularProgress className={classes.loading} size={30} />
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                getUserSubscriptions({
                  variables: {
                    userEmail,
                    product: "all",
                    platform
                  },
                  errorPolicy: "all",
                  fetchPolicy: "network-only"
                });
              }}
              disabled={userEmail === "" || addSubLoading}
            >
              <Search />
            </Button>
          )}
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Expiration"
              format="MM/dd/yyyy"
              value={expiration}
              onChange={date => setExpiration(date)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item className={classes.dropdown}>
          <FormControl>
            <InputLabel>Subscription: </InputLabel>
            <Select
              label="Subscription:"
              value={subscription}
              onChange={e => {
                setSubscription(e.target.value);
              }}
              input={<Input name="subscription" />}
            >
              {subscriptionLevels.map(p => (
                <MenuItem key={p} value={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {addSubLoading ? (
            <CircularProgress className={classes.loading} size={30} />
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={
                userEmail === "" ||
                !expiration ||
                !subscription ||
                getSubsLoading
              }
              onClick={() => {
                addUserSubscription({
                  variables: {
                    userEmail,
                    expiration,
                    product: subscription
                  },
                  errorPolicy: "all"
                });
              }}
            >
              <Typography variant="button">Add Subscription</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {!getSubsLoading && !getUserSubscriptionsError && data ? (
        <MaterialTable
          columns={columns}
          data={data.auth.userSubscriptions}
          isLoading={getSubsLoading}
          options={{
            columnsButton: true,
            search: false
          }}
          title="Subscriptions"
        />
      ) : null}
    </div>
  );
}
