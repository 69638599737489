import React, { Component } from "react";
import "typeface-roboto";
import { withStyles } from "@material-ui/core/styles";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { withRouter, BrowserRouter as Router, Route } from "react-router-dom";
import decode from "jwt-decode";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, Observable } from "apollo-link";

import "./App.css";
import { REFRESH_TOKEN_MUTATION } from "./mutations";
import { Login, Dashboard, Header } from "./components";

const httpLink = createHttpLink({
  uri:
    window.location.hostname === "localhost"
      ? "http://localhost:8050/graphql"
      : "https://adminql-api.intelql.com/graphql"
});

const request = operation => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });
};

// Request link
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => {
      /*
      We simply log the user out of the application if their token expires and
      currently we do not have token refresh logic in here.  Which seems fine as
      Mike and the crew can log back in after 2 weeks and this might help keep
      the admin panel locked down a tiny bit more if they just leave a tab open
      and forget about it.
      */
      if (error.code === "1201") {
        console.log("HARD LOGOUT");
        localStorage.removeItem("token");
        client.resetStore();
        window.location.href = "/login";
      }
      console.log(
        `[GraphQL error]: Message: ${error.code} ${error.title} ${error.detail}`
      );
      return null;
    });
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: ApolloLink.from([requestLink, errorLink, httpLink]),
  cache
});

const styles = {
  grow: {
    flexGrow: 1
  }
};

class App extends Component {
  async componentDidMount() {
    const token = localStorage.getItem("token");
    if (token != null) {
      const res = await client.mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: {
          token
        },
        errorPolicy: "all"
      });
      if (res && res.data && res.data.token) {
        const { token: newToken } = res.data.token;
        const claims = decode(newToken);

        if (!("admin_write" in claims.lvl) && !("admin_read" in claims.lvl)) {
          localStorage.removeItem("token");
          client.resetStore();
          this.props.history.push("/");
        } else {
          localStorage.setItem("token", newToken);
        }
      }
    }
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Header />
          <Router>
            <Route path="/login" component={Login} />
            <Route path="/" component={Dashboard} />
          </Router>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
