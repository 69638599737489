import gql from "graphql-tag"

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password, product: "admin") {
      token
    }
  }
`

export const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshToken($token: String!) {
    token(token: $token, product: "admin") {
      token
    }
  }
`

export const UPDATE_GAME_ANALYSIS = gql`
  mutation updateGameAnalysis($slugId: String!, $gameAnalysis: String!) {
    auth {
      updateGameAnalysis(slugId: $slugId, gameAnalysis: $gameAnalysis) {
        slugId
        gameAnalysis
      }
    }
  }
`

export const UPDATE_FEED_ZONE_SETTING = gql`
  mutation updateFeedZoneSetting(
    $leagueId: UUID!
    $zoneType: String!
    $maxLength: Int!
  ) {
    auth {
      updateFeedZoneSetting(
        leagueId: $leagueId
        zoneType: $zoneType
        maxLength: $maxLength
      ) {
        maxLength
      }
    }
  }
`

export const CREATE_PUSHNO = gql`
  mutation schedulePushNotification(
    $kwargs: JSONString
    $lastExecutionTime: DateTime
    $executionFrequency: Int!
    $usernames: [String]
  ) {
    auth {
      createScheduledOp(
        kwargs: $kwargs
        lastExecutionTime: $lastExecutionTime
        executionFrequency: $executionFrequency
        usernames: $usernames
        service: AUTH
        operation: "auth.scheduled.operations.pushno.pushno"
      ) {
        id
        kwargs
        lastExecutionTime
        executionFrequency
        usernames
      }
    }
  }
`

export const UPDATE_PUSHNO = gql`
  mutation modifyPushNotification(
    $id: UUID!
    $kwargs: JSONString
    $lastExecutionTime: DateTime
    $executionFrequency: Int
    $usernames: [String]
  ) {
    auth {
      modifyScheduledOp(
        id: $id
        kwargs: $kwargs
        lastExecutionTime: $lastExecutionTime
        executionFrequency: $executionFrequency
        usernames: $usernames
        service: AUTH
      ) {
        id
        kwargs
        lastExecutionTime
        executionFrequency
        usernames
      }
    }
  }
`

export const DELETE_SCHEDOP = gql`
  mutation deleteScheduledOp($id: UUID!, $service: Services!) {
    auth {
      deleteScheduledOp(id: $id, service: $service) {
        ok
      }
    }
  }
`

export const CREATE_POLL = gql`
  mutation createSurvey(
    $question: String!
    $answers: [String]
    $startDate: DateTime!
    $endDate: DateTime!
    $leagueId: UUID!
    $rank: Int
  ) {
    auth {
      createSurvey(
        question: $question
        answers: $answers
        startDate: $startDate
        endDate: $endDate
        leagueId: $leagueId
        rank: $rank
      ) {
        id
        survey {
          id
          league {
            id
            name
            __typename
          }
          startDate
          endDate
          question
          rank
          answers {
            id
            answer
            __typename
          }
          __typename
        }
        leagueId
        startDate
        endDate
        webPosition
        webHomePosition
        mobilePosition
        carouselPosition
        hidden
        __typename
      }
      __typename
    }
    __typename
  }
`

export const UPDATE_POLL = gql`
  mutation updateSurvey(
    $id: UUID!
    $question: String
    $startDate: DateTime
    $endDate: DateTime
    $leagueId: UUID
    $rank: Int
  ) {
    auth {
      updateSurvey(
        id: $id
        question: $question
        startDate: $startDate
        endDate: $endDate
        leagueId: $leagueId
        rank: $rank
      ) {
        id
        startDate
        endDate
        survey {
          id
          league {
            name
            id
            __typename
          }
          answers {
            id
            answer
            __typename
          }
          question
          rank
          startDate
          endDate
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`

export const HIDE_POLL = gql`
  mutation updateSurvey($id: UUID!, $endDate: DateTime) {
    auth {
      updateSurvey(id: $id, endDate: $endDate) {
        id
        survey {
          id
          league {
            name
            id
            __typename
          }
          answers {
            id
            answer
            __typename
          }
          question
          rank
          startDate
          endDate
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`

export const DELETE_POLL = gql`
  mutation deleteSurvey($id: UUID!) {
    auth {
      deleteSurvey(id: $id) {
        ok
        __typename
      }
      __typename
    }
    __typename
  }
`

export const CREATE_POLL_ANSWER = gql`
  mutation createSurveyAnswer($answer: String!, $surveyId: UUID!) {
    auth {
      createSurveyAnswer(answer: $answer, surveyId: $surveyId) {
        id
        answer
      }
    }
  }
`

export const UPDATE_POLL_ANSWER = gql`
  mutation updateSurveyAnswer($id: UUID!, $answer: String!) {
    auth {
      updateSurveyAnswer(id: $id, answer: $answer) {
        id
        answer
      }
    }
  }
`

export const DELETE_POLL_ANSWER = gql`
  mutation deleteSurveyAnswer($id: UUID!) {
    auth {
      deleteSurveyAnswer(id: $id) {
        ok
      }
    }
  }
`

export const DELETE_FEED_ENTITY = gql`
  mutation hideFeedEntity($id: UUID!) {
    auth {
      hideFeedEntity(id: $id) {
        ok
      }
    }
  }
`

export const UPDATE_FEED_ENTITY = gql`
  mutation updateFeedEntity(
    $id: UUID!
    $mobilePosition: Int
    $webPosition: Int
    $webHomePosition: Int
    $carouselPosition: Int
    $rightRailPosition: Int
  ) {
    auth {
      updateFeedEntity(
        id: $id
        mobilePosition: $mobilePosition
        webPosition: $webPosition
        webHomePosition: $webHomePosition
        carouselPosition: $carouselPosition
        rightRailPosition: $rightRailPosition
      ) {
        id
        createdAt
        updatedAt
        surveyId
        slugId
        externalId
        leagueId
        webPosition
        webHomePosition
        mobilePosition
        carouselPosition
        rightRailPosition
        externalJson
        entityType
        hidden
      }
    }
  }
`

export const UPSERT_FREE_EVENT_OVERRIDE = gql`
  mutation upsertFreeEventOverride(
    $leagueName: String!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    auth {
      upsertFreeEventOverride(
        leagueName: $leagueName
        startAt: $startAt
        endAt: $endAt
      ) {
        id
        createdAt
        leagueId
        startAt
        endAt
      }
    }
  }
`

export const DELETE_FREE_EVENT_OVERRIDE = gql`
  mutation deleteFreeEventOverrideById($id: UUID!) {
    auth {
      deleteFreeEventOverrideById(id: $id) {
        ok
      }
    }
  }
`

export const UPDATE_FREE_EVENTS_BY_RANGE = gql`
  mutation updateFreeEvents(
    $leagueName: String
    $startAt: DateTime
    $endAt: DateTime
    $freeGame: Boolean
  ) {
    auth {
      upsertFreeEventOverride(
        leagueName: $leagueName
        startAt: $startAt
        endAt: $endAt
        freeGame: $freeGame
      ) {
        updateCount
      }
    }
  }
`

export const UPDATE_FREE_EVENT = gql`
  mutation updateFreeEvent($slugId: String, $freeGame: Boolean!) {
    auth {
      updateFreeEvent(slugId: $slugId, freeGame: $freeGame) {
        id
        slugId
        freeGame
        startDate
      }
    }
  }
`

export const ADD_USER_SUBSCRIPTION = gql`
  mutation createUserSubscription(
    $product: String!
    $expiration: DateTime
    $userEmail: String!
  ) {
    auth {
      createUserSubscription(
        product: $product
        expiration: $expiration
        userEmail: $userEmail
        source: "ADMIN"
      ) {
        token
      }
    }
  }
`

export const DELETE_AFFILIATE_OFFER = gql`
  mutation deleteAffiliateOfferById($id: UUID!) {
    auth {
      deleteAffiliateOfferById(id: $id) {
        ok
      }
    }
  }
`

export const UPSERT_AFFILIATE_OFFER = gql`
  mutation upsertAffiliateOffer(
    $rank: Int!
    $stateId: UUID!
    $operatorId: UUID!
    $imageUrl: String
    $affiliateLink: String
    $bookName: String
    $welcomeOffer: String
    $howItWorks: String
    $unpreferredPayment: String
    $preferredPayment: String
    $rating: Float!
    $staffPick: Boolean
    $affiliateLinkWeb: String
    $updateExisting: Boolean!
    $promoCode: String
    $json: String!
  ) {
    auth {
      upsertAffiliateOffer(
        rank: $rank
        stateId: $stateId
        operatorId: $operatorId
        imageUrl: $imageUrl
        affiliateLink: $affiliateLink
        bookName: $bookName
        welcomeOffer: $welcomeOffer
        howItWorks: $howItWorks
        unpreferredPayment: $unpreferredPayment
        preferredPayment: $preferredPayment
        rating: $rating
        staffPick: $staffPick
        affiliateLinkWeb: $affiliateLinkWeb
        updateExisting: $updateExisting
        promoCode: $promoCode
        json: $json
      ) {
        id
        rank
        stateId
        operatorId
        imageUrl
        affiliateLink
        bookName
        welcomeOffer
        howItWorks
        unpreferredPayment
        preferredPayment
        rating
        staffPick
        startDate
        affiliateLinkWeb
        promoCode
        json
      }
    }
  }
`

// modifyScheduledOp(id: UUID!service: Services!operation: String leaseHolder: String lastExecutionTime: DateTimeleaseExpiryTime: DateTimeexecutionFrequency: Intusernames: [String]kwargs: JSONString): ModifyScheduledOp

export const BET_RESET_SCHEDULED_OPERATION = gql`
  mutation modifyScheduledOp(
    $id: UUID!
    $leaseExpiryTime: DateTime
    $lastExecutionTime: DateTime
  ) {
    auth {
      modifyScheduledOp(
        id: $id
        service: BET
        leaseExpiryTime: $leaseExpiryTime
        lastExecutionTime: $lastExecutionTime
      ) {
        id
      }
    }
  }
`

export const INTEL_RESET_SCHEDULED_OPERATION = gql`
  mutation modifyScheduledOp(
    $id: UUID!
    $leaseExpiryTime: DateTime
    $lastExecutionTime: DateTime
  ) {
    auth {
      modifyScheduledOp(
        id: $id
        service: INTEL
        leaseExpiryTime: $leaseExpiryTime
        lastExecutionTime: $lastExecutionTime
      ) {
        id
      }
    }
  }
`
