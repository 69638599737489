import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import {
  Subscriptions,
  Textsms,
  Games,
  AccessTimeRounded,
  AssessmentRounded
} from "@material-ui/icons";

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0
  },
  drawerPaper: {
    width: 240
  },
  toolbar: theme.mixins.toolbar
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class Navbar extends Component {
  _click_link(item) {
    if (item === "Pushes") {
      this.props.history.push("/pushes");
    } else if (item === "Subs") {
      this.props.history.push("/sub");
    } else if (item === "Free Games") {
      this.props.history.push("/free-games");
    } else if (item === "Scheduled Operations") {
      this.props.history.push("/scheduled-operations");
    } else if (item === "Game Analysis") {
      this.props.history.push("/game-analysis");
    }
  }
  _get_icon(item) {
    if (item === "Pushes") {
      return <Textsms />;
    } else if (item === "Subs") {
      return <Subscriptions />;
    } else if (item === "Free Games") {
      return <Games />;
    } else if (item === "Scheduled Operations") {
      return <AccessTimeRounded />;
    } else if (item === "Game Analysis") {
      return <AssessmentRounded />;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {[
            "Pushes",
            "Free Games",
            "Scheduled Operations",
            "Game Analysis",
            "Subs"
          ].map((text, index) => (
            <ListItemLink
              onClick={() => this._click_link(text)}
              button
              key={index}
            >
              <ListItemIcon>{this._get_icon(text)}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemLink>
          ))}
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(withRouter(Navbar));
